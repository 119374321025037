import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./components/home/home.component";
import {
    RetrieveFilesContainerComponent
} from "./retrieve-files/containers/retrieve-files-container/retrieve-files-container.component";
import { AccessDeniedComponent } from "./shared/components/access-denied/access-denied.component";

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'shared-files/list',
        component: RetrieveFilesContainerComponent
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
    // empty
}
