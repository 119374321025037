import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-email-verification-confirmation',
  templateUrl: './email-verification-confirmation.component.html',
  styleUrl: './email-verification-confirmation.component.scss'
})
export class EmailVerificationConfirmationComponent {
    private digit1: string = '';
    private digit2: string = '';
    private digit3: string = '';
    private digit4: string = '';
    private digit5: string = '';
    private digit6: string = '';

    private timeoutId = -1;

    form: FormGroup = this.formBuilder.group({
        digit1: ['', Validators.required],
        digit2: ['', Validators.required],
        digit3: ['', Validators.required],
        digit4: ['', Validators.required],
        digit5: ['', Validators.required],
        digit6: ['', Validators.required]
    });

    constructor(private formBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<EmailVerificationConfirmationComponent>) {
        // empty.
    }

    cancel() {
        const emailVerificationConfirmationData: EmailVerificationConfirmationData = {
            code: '',
            verified: false,
            cancelled: true
        };
        this.dialogRef.close(emailVerificationConfirmationData);
    }

    confirm() {
        const emailVerificationConfirmationData: EmailVerificationConfirmationData = {
            code: Object.values(this.form.value).join(''),
            verified: true,
            cancelled: false
        };
        this.dialogRef.close(emailVerificationConfirmationData);
    }

    handleFormFieldKeyDown(event: any, fieldId: number) {
        const validKeys = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter' ];
        const validDigits = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (event instanceof KeyboardEvent) {
            if (!validKeys.includes(event.key)) {
                event.preventDefault();
            } else if (validDigits.includes(event.key)) {
                if (fieldId < 6) {
                    const nextField = document.getElementById('digit' + (fieldId + 1));
                    if (nextField) {
                        this.timeoutId = window.setInterval(() => {
                            if (-1 !== this.timeoutId) {
                                window.clearInterval(this.timeoutId);
                                this.timeoutId = -1;
                            }
                            nextField.focus();
                        }, 50);
                    }
                }
            } else if ('Backspace' == event.key && fieldId > 1) {
                const previousField = document.getElementById('digit' + (fieldId - 1));
                if (previousField) {
                    this.timeoutId = window.setInterval(() => {
                        if (-1 !== this.timeoutId) {
                            window.clearInterval(this.timeoutId);
                            this.timeoutId = -1;
                        }
                        previousField.focus();
                    }, 50);
                }
            } else if ('Enter' == event.key && fieldId == 6) {
                this.confirm();
            }
        }

        // if (event instanceof KeyboardEvent) {
        //     if (event.key === 'Backspace') {
        //         if (fieldId > 1) {
        //             const previousField = document.getElementById('digit' + (fieldId - 1));
        //             if (previousField) {
        //                 this.timeoutId = window.setInterval(() => {
        //                     if (-1 !== this.timeoutId) {
        //                         window.clearInterval(this.timeoutId);
        //                         this.timeoutId = -1;
        //                     }
        //                     previousField.focus();
        //                 }, 50);
        //             }
        //         }
        //     } else if (event.key === 'Tab') {
        //         // allow.
        //         console.log('Tab key pressed');
        //     } else if (!(event.key in validDigits)) {
        //         event.preventDefault();
        //         return;
        //     } else {
        //         // Valid number entered
        //         if (fieldId < 6) {
        //             const nextField = document.getElementById('digit' + (fieldId + 1));
        //             if (nextField) {
        //                 this.timeoutId = window.setInterval(() => {
        //                     if (-1 !== this.timeoutId) {
        //                         window.clearInterval(this.timeoutId);
        //                         this.timeoutId = -1;
        //                     }
        //                     nextField.focus();
        //                 }, 50);
        //             }
        //         }
        //     }
        // }
    }
}

export interface EmailVerificationConfirmationData {
    code: string;
    verified: boolean;
    cancelled: boolean;
}
