import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-retrieve-files',
  templateUrl: './retrieve-files.component.html',
  styleUrl: './retrieve-files.component.scss'
})
export class RetrieveFilesComponent {
    @Input() requestUUID: string = '';
    @Input() shareInfo: ShareInfo = {
        shareId: '',
        senderEmail: '',
        message: ''
    }
    @Input() files: FileType[] = [];
    @Output() downloadFileEvent = new EventEmitter<SingleFileDownloadRequest>();

    downloadFile(filename: string) {
        this.downloadFileEvent.emit({ filename, requestUUID: this.requestUUID });
    }
}

export interface FileType {
    filename: string;
    filesize: number;
}

export interface SingleFileDownloadRequest {
    filename: string;
    requestUUID: string;
}

export interface ShareInfo {
    /**
     * Share ID - ID of the share.
     */
    shareId: string;
    /**
     * Email address of the sender of the file(s).
     */
    senderEmail: string;
    /**
     * Message created by the sender.
     */
    message: string;
}
