import { on, reducer } from "ts-action";
import {
    shareNowCompletedAction,
    shareNowConfirmAction,
    shareNowConfirmFailureAction,
    shareNowConfirmSuccessAction,
    shareNowLoadDataFromStorage,
    shareNowLoadDataFromStorageSuccess,
    shareNowLoadStateAction, shareNowRepeatConfirmationAction,
    shareNowRequestAction,
    shareNowRequestFailureAction,
    shareNowRequestSuccessAction,
    shareNowResetAction,
    shareNowUploadRequestAction,
    shareNowUploadRequestFailureAction,
    shareNowUploadRequestSuccessAction
} from "./share-now.actions";
import { FileRequest } from "../../../../generated/api";

export const shareNowReduxStoreName = 'shareNowStore';

export enum ShareNowStateEnum {
    INITIALIZED = 'INITIALIZED',
    REQUEST_INITIALIZED = 'REQUEST_INITIALIZED',
    REQUESTED = 'REQUESTED',
    REQUEST_FAILED = 'REQUEST_FAILED',
    CONFIRMATION_INITIALIZED = 'CONFIRMATION_INITIALIZED',
    CONFIRMED = 'CONFIRMED',
    CONFIRMATION_FAILED = 'CONFIRMATION_FAILED',
    UPLOAD_PENDING = 'UPLOAD_PENDING',
    UPLOAD_FAILED = 'UPLOAD_FAILED',
    FINISHED = 'FINISHED'
}

export interface ShareNowReduxStoreState {
    isLoading: boolean;
    files: File[],
    shareId?: string,
    confirmationCode?: string,
    error?: string;
    progressPercentage: number;
    senderEmail: string;
    recipientEmail: string;
    fileRequest: Array<FileRequest>;
    numFilesToShare: number;
    numDaysToShare: number;
    subject?: string;
    message?: string;
    currentFilename?: string;
    status: ShareNowStateEnum;
}

const initialShareNowReduxStoreState: ShareNowReduxStoreState = {
    isLoading: false,
    files: [],
    progressPercentage: 0,
    senderEmail: '',
    recipientEmail: '',
    fileRequest: [],
    numFilesToShare: 0,
    numDaysToShare: 0,
    status: ShareNowStateEnum.INITIALIZED
}

export const shareNowReducer = reducer<ShareNowReduxStoreState>(
    initialShareNowReduxStoreState,
    on(shareNowLoadStateAction, (state, { payload }) => ({
        ...state,
        ...payload
    })),
    on(shareNowRequestAction, (state, { payload }) => ({
        ...state,
        isLoading: true,
        senderEmail: payload.senderEmail,
        recipientEmail: payload.recipientEmail,
        fileRequest: payload.fileRequest,
        numFilesToShare: payload.numFilesToShare,
        numDaysToShare: payload.numDaysToShare,
        subject: payload.subject,
        message: payload.message,
        files: payload.files || [],
        hasMoreFiles: (payload.files ? payload.files.length > 0 : false),
        progressPercentage: 0,
        status: ShareNowStateEnum.REQUEST_INITIALIZED
    })),
    on(shareNowRequestSuccessAction, (state, { payload }) => ({
        ...state,
        isLoading: false,
        shareId: payload.shareId,
        status: ShareNowStateEnum.REQUESTED
    })),
    on(shareNowRequestFailureAction, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error,
        status: ShareNowStateEnum.REQUEST_FAILED
    })),
    on(shareNowConfirmAction, (state, { payload }) => ({
        ...state,
        isLoading: true,
        confirmationCode: payload.code,
        senderEmail: payload.email,
        requestUUID: payload.requestUUID,
        status: ShareNowStateEnum.CONFIRMATION_INITIALIZED
    })),
    on(shareNowConfirmSuccessAction, (state, { payload }) => ({
        ...state,
        isLoading: false,
        requestUUID: payload.confirmationCode,
        status: ShareNowStateEnum.CONFIRMED,
    })),
    on(shareNowConfirmFailureAction, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error,
        status: ShareNowStateEnum.CONFIRMATION_FAILED
    })),
    on(shareNowUploadRequestAction, (state, { payload }) => ({
        ...state,
        isLoading: true,
        status: ShareNowStateEnum.UPLOAD_PENDING,
        currentFilename: payload.filename
    })),
    on(shareNowUploadRequestSuccessAction, (state, { payload }) => ({
        ...state,
        isLoading: false,
        files: state.files.filter(file => file.name !== state.currentFilename),
        currentFilename: undefined,
        status: (state.files.filter(file => file.name != state.currentFilename).length === 0) ? ShareNowStateEnum.FINISHED : ShareNowStateEnum.UPLOAD_PENDING,
    })),
    on(shareNowUploadRequestFailureAction, (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload.error
    })),
    on(shareNowLoadDataFromStorage, (state) => (state)),
    on(shareNowLoadDataFromStorageSuccess, (state, { payload }) => ({
        ...initialShareNowReduxStoreState,
        ...payload
    })),
    on(shareNowCompletedAction, (state) => ({
        ...state,
        status: ShareNowStateEnum.FINISHED
    })),
    on(shareNowRepeatConfirmationAction, (state) => ({
        ...state,
        status: ShareNowStateEnum.REQUESTED,
        error: undefined
    })),
    on(shareNowResetAction, (state) => initialShareNowReduxStoreState)
);
