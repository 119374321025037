import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetrieveFilesComponent } from './components/retrieve-files/retrieve-files.component';
import { RetrieveFilesContainerComponent } from './containers/retrieve-files-container/retrieve-files-container.component';
import { MaterialModule } from "../material/material.module";
import { NgPipesModule } from "ngx-pipes";
import { AppModule } from "../app.module";
import { SharedModule } from "../shared/shared.module";
import { TranslocoModule } from "@jsverse/transloco";


@NgModule({
    declarations: [
        RetrieveFilesComponent,
        RetrieveFilesContainerComponent
    ],
    imports: [
        MaterialModule,
        CommonModule,
        NgPipesModule,
        SharedModule,
        TranslocoModule
    ],
    exports: [
        RetrieveFilesContainerComponent
    ]
})
export class RetrieveFilesModule {
}
