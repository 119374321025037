<ng-container *ngIf="'home' === this.template; else corporateTemplate">
    <div class="bg-div" style="background-image: url('https://picsum.photos/1920/1080'); background-size: cover; height: 100vh; width: 100%;">
        <app-header></app-header>
        <main>
            <router-outlet></router-outlet>
        </main>
        <footer>
            <app-footer></app-footer>
        </footer>
    </div>
</ng-container>
<ng-template #corporateTemplate>
    <div class="bg-div" style="background-image: url('https://picsum.photos/1920/1080'); background-size: cover; height: 100vh; width: 100%;">
        <app-header></app-header>
        <main>
            <router-outlet></router-outlet>
        </main>
        <footer>
            <app-footer></app-footer>
        </footer>
    </div>
</ng-template>
