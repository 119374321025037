import * as i0 from '@angular/core';
import { NgZone, InjectionToken, NgModule, Inject, Injector, Optional } from '@angular/core';
import * as i1 from 'mini-rx-store';
import { ReduxDevtoolsExtension, configureStore, Store, Actions, actions$, hasEffectMetaData, configureComponentStores } from 'mini-rx-store';
class NgReduxDevtoolsExtension extends ReduxDevtoolsExtension {
  constructor(options, injector) {
    super(options);
    this.injector = injector;
    this.ngZone = this.injector.get(NgZone);
  }
  updateState(state) {
    this.ngZone.run(() => {
      super.updateState(state);
    });
  }
}
const STORE_CONFIG = new InjectionToken('@mini-rx/store-config');
const FEATURE_NAMES = new InjectionToken('@mini-rx/feature-name');
const FEATURE_REDUCERS = new InjectionToken('@mini-rx/feature-reducer');
const FEATURE_CONFIGS = new InjectionToken('@mini-rx/feature-store-config');
function storeFactory(config, injector) {
  config.extensions = config.extensions && config.extensions.length ? config.extensions.map(ext => {
    if (ext.id === 3 /* REDUX_DEVTOOLS */) {
      // Use NgReduxDevtoolsExtension which uses NgZone.run (to make sure that Angular updates the View when using time-travel)
      // TODO check if this is still necessary in newer Angular versions (it works without NgZone in Angular 13)
      return new NgReduxDevtoolsExtension(ext.optionsForNgExtension, injector);
    }
    return ext;
  }) : config.extensions;
  return configureStore(config);
}
class StoreRootModule {
  constructor(store // Make sure store is initialized also if it is NOT injected in other services/components
  ) {
    this.store = store;
  }
}
StoreRootModule.ɵfac = function StoreRootModule_Factory(t) {
  return new (t || StoreRootModule)(i0.ɵɵinject(i1.Store));
};
StoreRootModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: StoreRootModule
});
StoreRootModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreRootModule, [{
    type: NgModule
  }], function () {
    return [{
      type: i1.Store
    }];
  }, null);
})();
class StoreFeatureModule {
  constructor(store, root,
  // Prevent feature states to be initialized before root state
  featureNames, reducers, configs) {
    this.store = store;
    featureNames.forEach((featureName, index) => {
      this.store.feature(featureName, reducers[index], configs[index]);
    });
  }
}
StoreFeatureModule.ɵfac = function StoreFeatureModule_Factory(t) {
  return new (t || StoreFeatureModule)(i0.ɵɵinject(i1.Store), i0.ɵɵinject(StoreRootModule), i0.ɵɵinject(FEATURE_NAMES), i0.ɵɵinject(FEATURE_REDUCERS), i0.ɵɵinject(FEATURE_CONFIGS));
};
StoreFeatureModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: StoreFeatureModule
});
StoreFeatureModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreFeatureModule, [{
    type: NgModule
  }], function () {
    return [{
      type: i1.Store
    }, {
      type: StoreRootModule
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [FEATURE_NAMES]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [FEATURE_REDUCERS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [FEATURE_CONFIGS]
      }]
    }];
  }, null);
})();
class StoreModule {
  static forRoot(config) {
    return {
      ngModule: StoreRootModule,
      providers: [{
        provide: STORE_CONFIG,
        useValue: config
      }, {
        provide: Store,
        useFactory: storeFactory,
        deps: [STORE_CONFIG, Injector]
      }, {
        provide: Actions,
        useValue: actions$
      }]
    };
  }
  static forFeature(featureName, reducer, config) {
    return {
      ngModule: StoreFeatureModule,
      providers: [{
        provide: FEATURE_NAMES,
        multi: true,
        useValue: featureName
      }, {
        provide: FEATURE_REDUCERS,
        multi: true,
        useValue: reducer
      }, {
        provide: FEATURE_CONFIGS,
        multi: true,
        useValue: config
      }]
    };
  }
}
StoreModule.ɵfac = function StoreModule_Factory(t) {
  return new (t || StoreModule)();
};
StoreModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: StoreModule
});
StoreModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreModule, [{
    type: NgModule
  }], null, null);
})();

// Credits go to Marko Stanimirović
const fromClassesWithEffectsToClassProviders = (injectionToken, classesWithEffects) => classesWithEffects.map(classWithEffects => ({
  provide: injectionToken,
  useClass: classWithEffects,
  multi: true
}));
const fromObjectsWithEffectsToEffects = objectsWithEffects => objectsWithEffects.reduce((acc, objectWithEffects) => {
  const effectsFromCurrentObject = Object.getOwnPropertyNames(objectWithEffects).reduce((acc, prop) => {
    const effect = objectWithEffects[prop];
    if (hasEffectMetaData(effect)) {
      acc.push(effect);
    }
    return acc;
  }, []);
  return [...acc, ...effectsFromCurrentObject];
}, []);

// Credits go to Marko Stanimirović
const OBJECTS_WITH_EFFECTS = new InjectionToken('@mini-rx/objectsWithEffects');
class EffectsModule {
  constructor(store, objectsWithEffects,
  // Make sure effects can select state from store, also if EffectsModule is registered before Store.forFeature
  storeRootModule, storeFeatureModule) {
    this.store = store;
    const effects = fromObjectsWithEffectsToEffects(objectsWithEffects);
    effects.forEach(effect => {
      this.store.effect(effect);
    });
  }
  static register(classesWithEffects) {
    return {
      ngModule: EffectsModule,
      providers: [...fromClassesWithEffectsToClassProviders(OBJECTS_WITH_EFFECTS, classesWithEffects)]
    };
  }
}
EffectsModule.ɵfac = function EffectsModule_Factory(t) {
  return new (t || EffectsModule)(i0.ɵɵinject(i1.Store), i0.ɵɵinject(OBJECTS_WITH_EFFECTS), i0.ɵɵinject(StoreRootModule, 8), i0.ɵɵinject(StoreFeatureModule, 8));
};
EffectsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: EffectsModule
});
EffectsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EffectsModule, [{
    type: NgModule
  }], function () {
    return [{
      type: i1.Store
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [OBJECTS_WITH_EFFECTS]
      }]
    }, {
      type: StoreRootModule,
      decorators: [{
        type: Optional
      }]
    }, {
      type: StoreFeatureModule,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
class ComponentStoreModule {
  static forRoot(config) {
    configureComponentStores(config);
    return {
      ngModule: ComponentStoreModule
    };
  }
}
ComponentStoreModule.ɵfac = function ComponentStoreModule_Factory(t) {
  return new (t || ComponentStoreModule)();
};
ComponentStoreModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ComponentStoreModule
});
ComponentStoreModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ComponentStoreModule, [{
    type: NgModule
  }], null, null);
})();

/*
 * Public API Surface of mini-rx-ng-devtools
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ComponentStoreModule, EffectsModule, StoreFeatureModule, StoreModule, StoreRootModule };
