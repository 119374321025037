<div>
    <form style="padding: 8px;" [formGroup]="this.shareForm">
        <div style="width:100%; display: flex; flex-direction: row;">
            <div>
                <mat-card class="custom-card">
                    <mat-card-header>
                        <mat-card-title transloco="sharenow.title">Share files</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <div
                            style="display: flex; flex-direction: row; flex-wrap: wrap; flex: 2; max-height: 108px; overflow-y: scroll;">
                            <div *ngFor="let file of files" style="flex: 1">
                                <div style="padding: 4px;">
                                    <mat-chip color="primary" selected="true"
                                              style="width: 120px; max-width: 120px !important; display: flex; flex-direction: row;">
                                        <span style="width: 80px !important; max-width: 80px !important;"
                                              [matTooltip]="file.name">{{ file.name | shorten: 8:'...' }}</span>
                                        <mat-icon style="color: red; position: relative; top: 7px; left: 4px;"
                                                  (click)="deleteAttachment(file)">close
                                        </mat-icon>
                                    </mat-chip>
                                </div>
                            </div>
                        </div>

                        <!-- Hidden file input for file picker -->
                        <input #fileInput [disabled]="this.sizeExceeded" type="file" style="display: none;"
                               (change)="addFiles($event)" multiple>

                        <!-- Hidden directory input for folder picker -->
                        <input #folderInput type="file" style="display: none;" webkitdirectory
                               (change)="addFolder($event)">

                        <ng-container *ngIf="!this.directoryChosen && !this.fileChosen">
                            <div style="display: flex; flex-direction: row;">
                                <div style="cursor: pointer;" (click)="fileInput.click()">
                                    <mat-icon style="height: 40px; width: 40px; font-size: 36px; color: blue;">
                                        add_circle
                                    </mat-icon>
                                </div>
                                <div style="display: flex; flex-direction: column;">
                            <span style="font-size: 18px; font-weight: 550; cursor:pointer;"
                                  transloco="sharenow.labels.addFiles"
                                  (click)="fileInput.click()">Add files</span>
                                    <span style="cursor:pointer; text-decoration: underline;"
                                          transloco="sharenow.labels.orSelectedFolder"
                                          (click)="folderInput.click()">or select a folder</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.fileChosen">
                            <div style="display: flex; flex-direction: row;">
                                <div style="cursor: pointer;" (click)="fileInput.click()">
                                    <mat-icon style="height: 40px; width: 40px; font-size: 36px;"
                                              [style.color]="this.sizeExceeded ? 'gray' : ''"
                                              [style.cursor]="this.sizeExceeded ? 'not-allowed' : 'pointer'"
                                    >add_circle
                                    </mat-icon>
                                </div>
                                <div style="padding-top: 8px;">
                                <span style="font-size: 18px; font-weight: 550; cursor:pointer;"
                                      [style.color]="this.sizeExceeded ? 'gray' : ''"
                                      [style.cursor]="this.sizeExceeded ? 'not-allowed' : 'pointer'"
                                      transloco="sharenow.labels.addMoreFiles"
                                      (click)="fileInput.click()">Add more file(s)</span>
                                </div>
                            </div>
                        </ng-container>
                        <div style="margin-left: -16px; padding-right: 32px; width: 100%; display: flex; flex-direction: row; gap: 16px; background-color: #dadada;
                    flex: 3; height: 30px; align-items: center; border-color: black; border-width: 1px;">
                            <div style="flex: 2; padding: 16px;">
                <span style="font-size: 14px; font-weight: 500;"
                      transloco="sharenow.labels.freeInfo">Up to 2 GB free</span>
                            </div>
                            <div style="display: flex; flex-direction: row;">
                                <mat-icon style="color: purple;">bolt</mat-icon>
                                <span
                                    style="color: purple; padding-top: 4px; font-size: 14px; font-weight: 500;">Increase limit</span>
                            </div>
                        </div>
                        <div style="width: 100%;">
                            <div class="form-control-container">
                                <mat-form-field *ngIf="this.shareForm.controls['shareType'].value === 'email'"
                                                appearance="outline">
                                    <mat-label transloco="sharenow.labels.recipientEmailAddress">Recipient</mat-label>
                                    <input matInput name="recipientEmailAddress" formControlName="recipientEmailAddress"
                                           [attr.placeholder]="'sharenow.placeholders.recipientEmailAddress' | transloco"
                                           required>
                                    <mat-hint class="form-field-hint" transloco="sharenow.hints.recipientEmailAddress">
                                        Please provide a
                                        valid email.
                                    </mat-hint>
                                    @if (this.shareForm.controls['recipientEmailAddress'].invalid) {
                                        <mat-error
                                            *ngFor="let error of this.shareForm.controls['recipientEmailAddress'].errors | keys">
                                            {{ this.errorMessages.get('recipientEmailAddress.' + error) | transloco }}
                                        </mat-error>
                                    }
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label transloco="sharenow.labels.senderEmailAddress">Your Email</mat-label>
                                    <input matInput name="sendEmailAddress" formControlName="senderEmailAddress"
                                           [attr.placeholder]="'sharenow.placeholders.senderEmailAddress' | transloco"
                                           required>
                                    <mat-hint class="form-field-hint" transloco="sharenow.hints.senderEmailAddress">
                                        Please
                                        provide a
                                        valid email.
                                    </mat-hint>
                                    @if (this.shareForm.controls['senderEmailAddress'].invalid) {
                                        <mat-error
                                            *ngFor="let error of this.shareForm.controls['senderEmailAddress'].errors | keys">
                                            {{ this.errorMessages.get('senderEmailAddress.' + error) | transloco }}
                                        </mat-error>
                                    }
                                </mat-form-field>
                                <mat-form-field *ngIf="this.shareForm.controls['shareType'].value === 'email'"
                                                appearance="outline">
                                    <mat-label transloco="sharenow.labels.title">Title</mat-label>
                                    <input matInput name="title" formControlName="title"
                                           [attr.placeholder]="'sharenow.placeholders.title' | transloco"
                                           minlength="2" maxlength="300"
                                    >
                                    <mat-hint class="form-field-hint" transloco="sharenow.hints.title">Please provide a
                                        valid title.
                                    </mat-hint>
                                    @if (this.shareForm.controls['title'].invalid) {
                                        <mat-error *ngFor="let error of this.shareForm.controls['title'].errors | keys">
                                            {{ this.errorMessages.get('title.' + error) | transloco }}
                                        </mat-error>
                                    }
                                </mat-form-field>
                                <mat-form-field *ngIf="this.shareForm.controls['shareType'].value === 'email'"
                                                appearance="outline">
                                    <mat-label transloco="sharenow.labels.message">Message</mat-label>
                                    <textarea matInput name="title" formControlName="message" rows="3"
                                              style="resize: none;"
                                              minlength="2" maxlength="1050"
                                              [attr.placeholder]="'sharenow.placeholders.message' | transloco">
                    </textarea>
                                    <mat-hint class="form-field-hint" transloco="sharenow.hints.message">Please provide
                                        a
                                        valid message.
                                    </mat-hint>
                                    @if (this.shareForm.controls['message'].invalid) {
                                        <mat-error
                                            *ngFor="let error of this.shareForm.controls['message'].errors | keys">
                                            {{ this.errorMessages.get('message.' + error) | transloco }}
                                        </mat-error>
                                    }
                                </mat-form-field>
                            </div>
                        </div>
                        <div style="margin-top: 44px;">
                            <button style="width: 100%; border-radius: 12px; margin-top: 8px;" color="accent" mat-button
                                    mat-raised-button
                                    type="submit"
                                    [disabled]="this.shareForm.disabled || this.shareForm.pristine || this.shareForm.invalid"
                                    transloco="sharenow.labels.share" (click)="this.submitForm();">Share now
                            </button>
                        </div>
                    </mat-card-content>

                    <mat-card-footer>
                        <div style="width: 100%; display: flex; justify-content: center; padding: 8px;">
                            <button mat-icon-button class="btn-options" (click)="toggleSideContainer()">
                                <mat-icon>settings</mat-icon>
                            </button>
                        </div>
                    </mat-card-footer>
                </mat-card>
            </div>
            <div [style.display]="this.showSideContainer ? 'flex' : 'none'"
                 style="min-width: 312px; background-color: #fafafa; opacity: 0.85; border-radius: 10px; margin-top: 100px;">
                <mat-card class="custom-card">
                    <mat-card-header class="mat-card-header-side">
                        <div
                            style="padding-left: 20px; width: 100%; display: flex; justify-content: space-between; align-items: center;">
                            <mat-card-title style="width: 100%;">Options</mat-card-title>
                        </div>
                        <div>
                            <button mat-icon-button style="" (click)="this.toggleSideContainer()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span style="padding-top: 2px;">Settings</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <div>
                                        <div>
                                            Please select if you would like to send an email or simply create a sharing
                                            link.
                                        </div>
                                        <div
                                            style="border-radius: 12px; border-width: 5px !important; border-color: lightpink;">
                                            <mat-radio-group formControlName="shareType"
                                                             (change)="this.onShareTypeChanged($event)">
                                                <mat-radio-button value="email" style="margin-top: 8px;" checked="true">
                                                    Email
                                                </mat-radio-button>
                                                <mat-radio-button value="link" style="margin-top: 8px;">Link
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </mat-panel-description>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span style="padding-top: 2px;">Validity</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-panel-description>
                                    <div style="display: flex; flex-direction: column; width: 100%;">
                                        <div style="width: 100%">
                                            <p>This setting defines how long file(s) will be available for download.</p>
                                        </div>
                                        <div style="display: flex; flex-direction: row;">
                                            <div>
                                                <mat-label><b>Days:</b></mat-label>
                                            </div>
                                            <div>
                                                <span><b>{{ this.allowedValidityDayValues[this.shareForm.controls['validityInDaysIndex'].value] }}</b></span>
                                            </div>
                                        </div>
                                        <div style="display: flex; flex-direction: row">
                                            <mat-slider style="width: 100%; margin-left: -2px;"
                                                        [min]="0"
                                                        [max]="allowedValidityDayValues.length - 1"
                                                        [step]="1"
                                                        thumbLabel
                                                        showTickMarks
                                            >
                                                <input matSliderThumb formControlName="validityInDaysIndex"
                                                       [(value)]="selectedDayValueIndex" #slider>
                                            </mat-slider>
                                        </div>
                                    </div>
                                </mat-panel-description>
                            </mat-expansion-panel>
                            <mat-expansion-panel disabled>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span>Get Paid</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                            <mat-expansion-panel disabled>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span>Customize Download Page</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                            <mat-expansion-panel disabled>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span>Protect Transfer</span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<!--<div>-->
<!--    <button mat-button color="accent" (click)="this.showConfirmationDialog()">Toggle Side Container</button>-->
<!--</div>-->
<div *ngIf="this.isUploading"
     style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; z-index: 1000; background-color: gray; opacity: 0.7">
    <div style="display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                background-color: rgba(128, 128, 128, 0.8);">
        <div style="display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;">
            <div>
                <mat-spinner></mat-spinner>
            </div>
            <div style="padding-top: 12px; display: flex; flex-direction: column; align-items: center">
                <span style="color: white; font-size: 24px; font-weight: bold;">Uploading files. Please wait...</span>
                <span
                    style="color: white; font-size: 18px; font-weight: bold; padding-top: 12px;">{{ this.uploadProgress }}
                    %</span>
            </div>
        </div>
    </div>
</div>
<!--<div class="upload-overlay" *ngIf="this.isUploading">-->
<!--    <div class="spinner-container">-->
<!--        <mat-spinner diameter="50"></mat-spinner>-->
<!--        <div class="upload-status">-->
<!--            Uploading {{ this.currentFilename }} - {{ uploadProgress }}%-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<div>
    <p>
        {{ this.stateAsStr }}
    </p>
</div>
