<div style="overflow-x: hidden; overflow-y: hidden; height: 30%; width: auto;">
    <div style="position: absolute; top: 5px; right: 5px; z-index: 100;">
        <button mat-icon-button (click)="this.cancel();">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-card>
        <mat-card-header>
            <div>
                <h1 style="color: darkgray;">Verify Email</h1>
            </div>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="this.form">
                <p style="color: darkgrey;">Please confirm your email address by entering the code you received via
                    email.</p>
                <div style="display: flex; flex-direction: row; width: 100%; justify-items: center; justify-content: center; align-items: center;">
                    <mat-form-field appearance="outline">
                        <input id="digit1" matInput type="text" maxlength="1" (keydown)="this.handleFormFieldKeyDown($event, 1)" formControlName="digit1">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input id="digit2" matInput type="text" maxlength="1" (keydown)="this.handleFormFieldKeyDown($event, 2)" formControlName="digit2">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input id="digit3" matInput type="text" maxlength="1" (keydown)="this.handleFormFieldKeyDown($event, 3)" formControlName="digit3">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input id="digit4" matInput type="text" maxlength="1" (keydown)="this.handleFormFieldKeyDown($event, 4)" formControlName="digit4">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input id="digit5" matInput type="text" maxlength="1" (keydown)="this.handleFormFieldKeyDown($event, 5)" formControlName="digit5">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <input id="digit6" matInput type="text" maxlength="1" (keydown)="this.handleFormFieldKeyDown($event, 6)" formControlName="digit6">
                    </mat-form-field>
                </div>
            </form>
        </mat-card-content>
        <mat-card-footer>
            <div style="display: flex; justify-content: space-between; padding-left: 10px; padding-right: 10px;">
                <button mat-raised-button color="primary" (click)="this.confirm()" [disabled]="this.form.pristine || this.form.invalid">Confirm</button>
                <button mat-raised-button color="accent" (click)="this.cancel()">Cancel</button>
            </div>
        </mat-card-footer>
    </mat-card>
</div>
