import { Component } from '@angular/core';
import { ShareNowService } from "../../services/share-now.service";
import { map, Observable, of } from "rxjs";
import { ANONYMOUS_USER_PROFILE_MODEL, UserProfileModel } from "../../../models/user-profile.model";
import { AuthenticationWrapperService } from "../../../services/authentication-wrapper.service";

@Component({
  selector: 'app-share-now-container',
  templateUrl: './share-now-container.component.html',
  styleUrl: './share-now-container.component.scss'
})
export class ShareNowContainerComponent {
    protected userProfileModel$: Observable<UserProfileModel> = of(ANONYMOUS_USER_PROFILE_MODEL);
    protected errorMessage$: Observable<string>;
    protected emptyUserProfileModule: UserProfileModel = ANONYMOUS_USER_PROFILE_MODEL;

    constructor(private readonly shareNowService: ShareNowService,
                private readonly authenticationWrapperService: AuthenticationWrapperService) {
        this.errorMessage$ = this.shareNowService.getShareNowState().pipe(
            map((state) => state.error ?? '')
        );
        this.userProfileModel$ = this.authenticationWrapperService.getUserProfile();
    }

    reset(): void {
        this.shareNowService.resetShareNowState();
    }
}
