import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule, StoreModule } from "mini-rx-store-ng";
import { ShareNowEffects } from "./state/share-now.effects";
import { shareNowReducer, shareNowReduxStoreName } from "./state/share-now.reducer";
import { ShareNowComponent } from "./components/share-now/share-now.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material/material.module";
import { TranslocoModule } from "@jsverse/transloco";
import { NgPipesModule } from "ngx-pipes";
import { ShareNowContainerComponent } from './containers/share-now-container/share-now-container.component';


@NgModule({
    declarations: [
        ShareNowComponent,
        ShareNowContainerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature(shareNowReduxStoreName, shareNowReducer),
        EffectsModule.register([ ShareNowEffects ]),
        MaterialModule,
        TranslocoModule,
        NgPipesModule
    ],
    exports: [
        ShareNowContainerComponent
    ]
})
export class ShareNowModule {
    // empty
}
