import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
    FileRequest,
    FileUploadRequest,
    FileUploadResponse,
    PublicShareResourceService,
    SharingRequest
} from "../../../../../generated/api";
import { MatDialog } from "@angular/material/dialog";
import {
    EmailVerificationConfirmationComponent
} from "../../../components/dialogs/email-verification-confirmation/email-verification-confirmation.component";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { ShareNowService } from "../../services/share-now.service";
import { ShareNowStateEnum } from "../../state/share-now.reducer";
import { NotificationService } from "../../../services/notification.service";
import { Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ANONYMOUS_USER_PROFILE_MODEL, UserProfileModel } from "../../../models/user-profile.model";

const errorMessages: Map<string, string> = new Map<string, string>();
errorMessages.set('recipientEmailAddress.email', marker('sharenow.errors.recipientEmail.invalid'));
errorMessages.set('recipientEmailAddress.required', marker('sharenow.errors.recipientEmail.required'));
errorMessages.set('senderEmailAddress.email', marker('sharenow.errors.senderEmail.invalid'));
errorMessages.set('senderEmailAddress.required', marker('sharenow.errors.senderEmail.required'));
errorMessages.set('title.minlength', marker('sharenow.errors.title.minlength'));
errorMessages.set('title.maxlength', marker('sharenow.errors.title.maxlength'));
errorMessages.set('message.minlength', marker('sharenow.errors.message.minlength'));
errorMessages.set('message.maxlength', marker('sharenow.errors.message.maxlength'));

@UntilDestroy()
@Component({
    selector: 'app-share-now',
    templateUrl: './share-now.component.html',
    styleUrl: './share-now.component.scss'
})
export class ShareNowComponent implements OnChanges {
    @Input() errorMessage = '';
    @Output() handleReset: EventEmitter<void> = new EventEmitter<void>(true);
    @Input() userProfileModel: UserProfileModel = ANONYMOUS_USER_PROFILE_MODEL;

    protected shareForm: FormGroup;
    protected errorMessages = errorMessages;
    protected files: File[] = [];

    protected showSideContainer = false;
    protected fileChosen = false;
    protected directoryChosen = false;
    protected absoluteSizeLimit = 2 * 1024 * 1000 * 1000; // 2 GB
    protected usedSizeLimit = 0;
    protected sizeExceeded = false;
    protected allowedValidityDayValues = [ 1, 3 ];
    protected selectedDayValueIndex = 0;

    // private readonly recipientAddressControl = new FormControl('jpenner@sybec.com', [ Validators.required, Validators.email ]);
    // private readonly titleControl = new FormControl('Test Subject', [ Validators.minLength(2), Validators.maxLength(255) ]);
    // private readonly messageControl = new FormControl('Test Message', [ Validators.minLength(2), Validators.maxLength(1024) ]);
    private recipientAddressControl = new FormControl('', [ Validators.required, Validators.email ]);
    private titleControl = new FormControl('', [ Validators.minLength(2), Validators.maxLength(255) ]);
    private messageControl = new FormControl('', [ Validators.minLength(2), Validators.maxLength(1024) ]);

    protected isPaidSubscription = false;

    protected isUploading = false;
    protected currentFilename: string = '';
    protected uploadProgress = 0.0;

    stateAsStr: string = '';

    // @ts-ignore
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    constructor(private readonly formBuilder: FormBuilder, private readonly matDialog: MatDialog,
                private readonly publicShareResourceService: PublicShareResourceService,
                private readonly httpClient: HttpClient, private readonly shareNowService: ShareNowService,
                private readonly notificationService: NotificationService) {

        // this.shareForm = this.formBuilder.group({
        //     recipientEmailAddress: this.recipientAddressControl,
        //     senderEmailAddress: new FormControl('jpenner@sybec.net', [ Validators.required, Validators.email ]),
        //     title: this.titleControl,
        //     message: this.messageControl,
        //     validityInDaysIndex: new FormControl(0, [ Validators.required ]),
        //     validityInDays: new FormControl(3, [ Validators.required ]),
        //     shareType: new FormControl('email', [ Validators.required ]),
        //     hasFiles: new FormControl(false, [ Validators.requiredTrue ]),
        // });
        this.shareForm = this.formBuilder.group({
            recipientEmailAddress: this.recipientAddressControl,
            senderEmailAddress: new FormControl('', [ Validators.required, Validators.email ]),
            title: this.titleControl,
            message: this.messageControl,
            validityInDaysIndex: new FormControl(0, [ Validators.required ]),
            validityInDays: new FormControl(3, [ Validators.required ]),
            shareType: new FormControl('email', [ Validators.required ]),
            hasFiles: new FormControl(false, [ Validators.requiredTrue ]),
        });

        this.shareNowService.getShareNowState().pipe(
            untilDestroyed(this)
        ).subscribe((state) => {
            switch (state.status) {
                case ShareNowStateEnum.INITIALIZED:
                    // Do nothing
                    break;
                case ShareNowStateEnum.REQUEST_INITIALIZED:
                    // Do nothing
                    break;
                case ShareNowStateEnum.REQUESTED:
                    const dialogRef = this.matDialog.open(EmailVerificationConfirmationComponent, {
                        width: '600px',
                        disableClose: true,
                        autoFocus: true,
                        data: {
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No'
                        }
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result?.verified) {
                            console.log('User confirmed the sharing with data ' + JSON.stringify(result));
                            this.shareNowService.confirmShareNowPublicSharing(this.shareForm.controls[ 'senderEmailAddress' ].value, result.code, state.shareId || '');
                        } else if (result?.cancelled) {
                            console.log('User cancelled the sharing with data ' + JSON.stringify(result));
                            this.shareForm.enable({ onlySelf: true });
                            this.reset();
                        }
                    });
                    break;
                case ShareNowStateEnum.REQUEST_FAILED:
                    console.log('Request failed');
                    this.stateAsStr = JSON.stringify(state);
                    if (undefined != state.error && null ! + state.error && state.error !== '') {
                        this.notificationService.showError(state.error);
                    } else {
                        this.notificationService.showError(marker('Failed to share files. Please try again later or contact support.'));
                    }

                    this.reset();
                    break;
                case ShareNowStateEnum.CONFIRMATION_INITIALIZED:
                    // Do nothing
                    break;
                case ShareNowStateEnum.CONFIRMED:
                    this.isUploading = true;
                    this.files.forEach((fileToUpload) => {
                        this.currentFilename = fileToUpload.name;
                        const fileUploadRequest: FileUploadRequest = {
                            filename: fileToUpload.name,
                            requestUUID: state.shareId ?? '',
                            mimeType: 'application/octet-stream',
                            size: fileToUpload.size
                        };

                        this.publicShareResourceService.requestFileUpload(fileUploadRequest).subscribe((response: FileUploadResponse) => {
                            console.log('Upload request response:', response);

                            // Use fileToUpload directly instead of wrapping it in FormData
                            this.httpClient.put(response.url, fileToUpload, {
                                headers: {
                                    'Content-Type': fileToUpload.type || 'application/octet-stream'  // Set the correct content type
                                },
                                reportProgress: true,
                                observe: 'events'
                            }).pipe(
                                untilDestroyed(this)
                            ).subscribe((event) => {
                                if (event.type === HttpEventType.UploadProgress) {
                                    // @ts-ignore
                                    this.uploadProgress = Math.round(100 * event.loaded / event.total);
                                    if (100 === this.uploadProgress) {
                                        this.shareNowService.setCompleted();
                                    }
                                } else if (event.type === HttpEventType.Response) {
                                    console.log('Upload response:', event.body);
                                }
                            });
                        });

                        this.uploadProgress = 100.0;
                        this.isUploading = false;

                        // this.shareForm.reset(); // TODO: Reset the form after successful upload
                        // this.files = []; // TODO: Reset the files after successful upload
                        // this.shareForm.enable({onlySelf: true}); // TODO: Enable the form after successful upload
                    });
                    break;
                case ShareNowStateEnum.CONFIRMATION_FAILED:
                    this.uploadProgress = 100.0;
                    this.isUploading = false;

                    if (undefined != state.error && null != state.error && state.error !== '') {
                        this.notificationService.showError(state.error);
                    } else {
                        this.notificationService.showError(marker('Failed to confirm sharing request. Please try again later or contact support.'));
                    }

                    this.shareNowService.setStateToConfirm();

                    break;
                case ShareNowStateEnum.UPLOAD_PENDING:
                    // Do nothing
                    break;
                case ShareNowStateEnum.UPLOAD_FAILED:
                    // Do nothing
                    break;
                case ShareNowStateEnum.FINISHED:
                    this.publicShareResourceService.confirmShare(state.shareId || '').subscribe((response) => {
                        console.log('Share confirmation response:', response);
                        this.notificationService.showSuccess('Files shared successfully');
                        this.reset();
                        this.isUploading = false;
                    });
                    break;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes[ 'error' ]?.currentValue) {
            this.notificationService.showError(changes[ 'error' ].currentValue);
        }
        if (changes['userProfileModel']?.currentValue) {
            const userProfileModel: UserProfileModel = changes['userProfileModel'].currentValue;
            if (userProfileModel.authenticated) {
                this.shareForm.controls['senderEmailAddress'].setValue(userProfileModel.email);
                this.shareForm.controls['senderEmailAddress'].disable();
            }
        }
    }

    addFiles(event: Event): void {
        const inputElement = event.target as HTMLInputElement;

        if (inputElement.files) {
            console.log('Files selected:', inputElement.files);

            let totalSize = 0;
            // Direct iteration using for...of
            for (const file of Array.from(inputElement.files)) {
                console.log(`File: ${ file.name }, Size: ${ file.size }, Type: ${ file.type }`);
                totalSize += file.size;
            }
            if (totalSize + this.usedSizeLimit > this.absoluteSizeLimit) {
                this.sizeExceeded = true;
            } else {
                this.usedSizeLimit += totalSize;

                for (const file of Array.from(inputElement.files)) {
                    let found = false;
                    for (const existingFile of this.files) {
                        if (existingFile.name === file.name) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) this.files.push(file);
                }
                if (this.files && this.files.length > 0) {
                    this.shareForm.controls[ 'hasFiles' ].setValue(true);
                    this.fileChosen = true;
                }
            }
        }
        this.fileInput.nativeElement.value = '';
    }

    addFolder(event: Event): void {
        // Trigger the hidden directory picker input element
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files) {
            console.log('Directory selected:', inputElement.files);
            // Add logic to handle the selected directory here
            let totalSize = 0;
            for (const file of Array.from(inputElement.files)) {
                console.log('File:', file);
                // Add logic to handle the selected files here
                totalSize += file.size;
            }
            if (totalSize > this.absoluteSizeLimit) {
                this.sizeExceeded = true;
            } else {
                this.usedSizeLimit += totalSize;
            }
            for (const file of Array.from(inputElement.files)) {
                this.files.push(file);
            }

            if (this.files && this.files.length > 0) {
                this.shareForm.controls[ 'hasFiles' ].setValue(true);
                this.directoryChosen = true;
            }
        }
    }

    submitForm(): void {
        let requestUUID = '';
        if (this.shareForm.valid) {
            this.shareForm.disable({ onlySelf: true });

            const fileRequestList: FileRequest[] = this.files.map((file: File) => {
                return {
                    filename: file.name,
                    fileSize: file.size
                };
            });

            if (this.shareForm.controls[ 'shareType' ].value === 'link') {
                alert('Link sharing is not implemented yet.');
                return;
            }

            const sharingRequest: SharingRequest = {
                senderEmail: this.shareForm.controls[ 'senderEmailAddress' ].value,
                recipientEmail: this.shareForm.controls[ 'recipientEmailAddress' ].value,
                subject: this.shareForm.controls[ 'title' ].value,
                message: this.shareForm.controls[ 'message' ].value,
                fileRequest: fileRequestList,
                numDaysToShare: this.shareForm.controls[ 'validityInDays' ].value,
                numFilesToShare: this.files.length,
            };

            this.shareNowService.requestShareNowPublicSharing(
                fileRequestList,
                this.files,
                this.shareForm.controls[ 'message' ].value,
                this.shareForm.controls[ 'validityInDays' ].value,
                this.files.length,
                this.shareForm.controls[ 'recipientEmailAddress' ].value,
                this.shareForm.controls[ 'senderEmailAddress' ].value,
                this.shareForm.controls[ 'title' ].value);
        }
    }

    toggleSideContainer(): void {
        this.showSideContainer = !this.showSideContainer;
    }

    onShareTypeChanged(event: any):
        void {
        console.log('Share type changed:', event.value);
        if ('link' === event.value
        ) {
            this.shareForm.removeControl('recipientEmailAddress');
            this.shareForm.removeControl('title');
            this.shareForm.removeControl('message');
        } else {
            this.shareForm.addControl('recipientEmailAddress', this.recipientAddressControl);
            this.shareForm.addControl('title', this.titleControl);
            this.shareForm.addControl('message', this.messageControl);
        }
    }

    deleteAttachment(file: File): void {
        const index = this.files.indexOf(file);
        if (index > -1
        ) {
            this.files.splice(index, 1);
            this.usedSizeLimit -= file.size;
            if (this.files.length === 0) {
                this.shareForm.controls[ 'hasFiles' ].setValue(false);
                this.fileChosen = false;
            }
        }
    }

    showConfirmationDialog(): void {
        const dialogRef = this.matDialog.open(EmailVerificationConfirmationComponent, {
            width: '600px',
            disableClose: true,
            autoFocus: true,
            data: {
                title: 'Confirmation',
                message: 'Are you sure you want to share the selected files?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.verified) {
                console.log('User confirmed the sharing with data ' + JSON.stringify(result));
                // this.submitForm();
            } else if (result && result.cancelled) {
                console.log('User cancelled the sharing with data ' + JSON.stringify(result));
            }
        });
    }

    private reset(): void {
        this.recipientAddressControl.reset();
        this.titleControl.reset();
        this.messageControl.reset();

        this.shareForm = this.formBuilder.group({
            recipientEmailAddress: this.recipientAddressControl,
            senderEmailAddress: new FormControl('jpenner@sybec.net', [ Validators.required, Validators.email ]),
            title: this.titleControl,
            message: this.messageControl,
            validityInDaysIndex: new FormControl(0, [ Validators.required ]),
            validityInDays: new FormControl(3, [ Validators.required ]),
            shareType: new FormControl('email', [ Validators.required ]),
            hasFiles: new FormControl(false, [ Validators.requiredTrue ]),
        });
        // this.shareForm = this.formBuilder.group({
        //     recipientEmailAddress: this.recipientAddressControl,
        //     senderEmailAddress: new FormControl('', [ Validators.required, Validators.email ]),
        //     title: this.titleControl,
        //     message: this.messageControl,
        //     validityInDaysIndex: new FormControl(0, [ Validators.required ]),
        //     validityInDays: new FormControl(3, [ Validators.required ]),
        //     shareType: new FormControl('email', [ Validators.required ]),
        //     hasFiles: new FormControl(false, [ Validators.requiredTrue ]),
        // });

        this.shareForm.enable({ onlySelf: true });

        this.files = [];
        this.currentFilename = '';
        this.uploadProgress = 0.0;
        this.shareNowService.resetShareNowState();
    }
}
