export function on(...args) {
    const reducer = args.pop();
    const types = args.reduce((result, creator) => [...result, creator.type], []);
    return { reducer, types };
}
export function reducer(initialState, ...ons) {
    const map = new Map();
    for (let on of ons) {
        for (let type of on.types) {
            if (!map.has(type)) {
                map.set(type, on.reducer);
            }
            else {
                console.warn(`${type} already specified in an 'on' call.`);
            }
        }
    }
    return function (state = initialState, action) {
        const reducer = map.get(action.type);
        return reducer ? reducer(state, action) : state;
    };
}
