import { Injectable, isDevMode, NgModule } from '@angular/core';
import {
    provideTransloco,
    Translation,
    TRANSLOCO_LOADER,
    TranslocoLoader,
    TranslocoModule,
    TranslocoService
} from "@jsverse/transloco";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { provideTranslocoLocale, TranslocoDatePipe, TranslocoLocaleModule } from "@jsverse/transloco-locale";

/**
 * Service to load the translations from the server.
 * @author jp
 */
@Injectable(
    { providedIn: 'root' }
)
export class TranslocoHttpLoader implements TranslocoLoader {
    /**
     * Base HREF for the i18n files.
     * @private
     */
    private assetBaseHref = environment.assetBaseHref;

    /**
     * Constructor.
     * @param http HttpClient used to fetch the json file.
     */
    constructor(private http: HttpClient) {
        // empty.
    }

    /**
     * Returns an Observable of Translation, which is used for providing the same.
     * @param lang string identifying the language for which a translation is to be loaded.
     */
    getTranslation(lang: string): Observable<Translation> {
        return this.http.get<Translation>(`${ this.assetBaseHref }/assets/i18n/${ lang }.json`);
    }
}

@NgModule({
    exports: [
        TranslocoModule,
        TranslocoLocaleModule,
        TranslocoDatePipe
    ],
    declarations: [],
    imports: [
        HttpClientModule,
        TranslocoModule,
        TranslocoLocaleModule
    ],
    providers: [
        provideTransloco({
            config: {
                availableLangs: [ 'en', 'de' ],
                defaultLang: 'en',
                fallbackLang: 'en',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            }
        }),
        provideTranslocoLocale({
            defaultLocale: 'en',
            defaultCurrency: 'USD',
            langToLocaleMapping: {
                en: 'en-US',
                de: 'de-DE'
            },
            localeToCurrencyMapping: {
                'en-US': 'USD',
                'de-DE': 'EUR'
            }
        }),
        {
            provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader
        },
        TranslocoService
    ]
})
export class TranslocoRootModule {
}
