import { saveAs } from 'file-saver';
import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { catchError, map, Observable, of, switchMap } from "rxjs";
import {
    FileType,
    ShareInfo,
    SingleFileDownloadRequest
} from "../../components/retrieve-files/retrieve-files.component";
import {
    PublicSharedFilesResourceService,
    PublicShareFetchResponse,
    PublicShareFileInfo,
    PublicShareFileList,
    PublicShareResourceService
} from "../../../../../generated/api";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "../../../services/notification.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'app-retrieve-files-container',
    templateUrl: './retrieve-files-container.component.html',
    styleUrl: './retrieve-files-container.component.scss'
})
export class RetrieveFilesContainerComponent {
    protected requestUUID$: Observable<string>;
    protected filelist$: Observable<FileType[]> = of([]);
    protected page = 1;
    protected numItemsPerPage = 10;
    protected EMPTY_SHARE_INFO: ShareInfo = {
        shareId: '',
        senderEmail: '',
        message: ''
    };
    protected shareInfo: Observable<ShareInfo> = of(this.EMPTY_SHARE_INFO);

    constructor(private readonly http: HttpClient,
                private readonly activatedRoute: ActivatedRoute,
                private readonly publicShareResourceService: PublicShareResourceService,
                private readonly publicSharedFilesResourceService: PublicSharedFilesResourceService,
                private readonly notificationService: NotificationService) {
        this.requestUUID$ = this.activatedRoute.queryParams.pipe(
            map((params) => {
                    return params[ 'id' ] || '';
                }
            ));

        this.shareInfo = this.activatedRoute.queryParams.pipe(
            switchMap((params) => {
                this.publicShareResourceService.fetchPublicShareRequestInfo(params[ 'id' ] as string).subscribe((response: PublicShareFetchResponse) => {
                    console.log('response', response);
                });
                // @ts-ignore
                return this.publicShareResourceService.fetchPublicShareRequestInfo(params[ 'id' ] as string).pipe(
                    map((response: PublicShareFetchResponse) => {
                        return {
                            shareId: response.shareId || '',
                            senderEmail: response.sender || '',
                            message: response.message || ''
                        };
                    })
                );
            })
        )

        this.filelist$ = this.activatedRoute.queryParams.pipe(
            switchMap((params) => {
                // @ts-ignore
                return this.publicSharedFilesResourceService.retrieveFileList(params[ 'id' ] as string, this.numItemsPerPage, this.page).pipe(
                    map((response: PublicShareFileList) => {
                        return this.mapFileList(response);
                    })
                );
            }),
            catchError((error) => {
                this.notificationService.showError('Failed to retrieve file list');
                console.error('error retrieving file list', error);
                return of([]);
            })
        );
    }

    private mapFileList(response: PublicShareFileList) {
        return response.files?.map((file: PublicShareFileInfo) => {
            return {
                filename: file.filename ?? '',
                filesize: file.filesize ?? 0
            };
        }) || [];
    }

    protected handleSingleFileDownload(event: SingleFileDownloadRequest) {
        this.notificationService.showSuccess('Download started for ' + event.filename);
        this.publicSharedFilesResourceService.requestFileDownloadLink({
            requestUUID: event.requestUUID,
            filename: event.filename
        }).subscribe((response) => {
            const downloadLink = response.downloadLink ?? '';

            if (downloadLink) {
                // Use HttpClient to fetch the file as a blob from the signed URL
                this.http.get(downloadLink, { responseType: 'blob' }).pipe(
                    untilDestroyed(this)
                ).subscribe((blob) => {
                    // Trigger the download using file-saver's saveAs method
                    saveAs(blob, event.filename);
                    this.notificationService.showSuccess('Download completed for ' + event.filename);
                }, (error) => {
                    console.error('Failed to download file:', error);
                });
            } else {
                this.notificationService.showError('Invalid download link. Please try again later.');
                console.error('Invalid download link');
            }
        })
    }
}
