<div id="share-content" *ngIf="this.shareInfo.message.length > 0">
    <mat-card>
        <mat-card-header>
            <mat-card-title transloco="viewFiles.title">Download shared Files</mat-card-title>
        </mat-card-header>
        <mat-card-content style="overflow-y: hidden;">
            <div>
                <div class="flex-table">
                    <div class="flex-row">
                        <div class="flex-cell" transloco="viewFiles.sender">Sender:</div>
                        <div class="flex-cell">{{ this.shareInfo.senderEmail }}</div>
                    </div>
                    <div class="flex-row">
                        <div class="flex-cell" transloco="viewFiles.message">Message:</div>
                        <div class="flex-cell" style="max-height: 200px; overflow-y: auto;">{{ this.shareInfo.message }}</div>
                    </div>
                </div>
            </div>
            <div>
                <div class="flex-table">
                    <div class="flex-row">
                        <div class="flex-cell" transloco="viewFiles.files">Files:</div>
                        <div class="flex-cell" style="max-height: 200px; overflow-y: auto;">
                            <div *ngFor="let file of this.files">
                                <mat-chip (click)="this.downloadFile(file.filename)" matTooltip="{{ file.filename }} - {{ file.filesize | bytes: 2}}">{{ file.filename | shorten: 20 }}</mat-chip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-footer>
            <p transloco="viewFiles.footer.comment">You can download your files by clicking on the chip!</p>
        </mat-card-footer>
    </mat-card>
</div>
<app-asset-content></app-asset-content>
