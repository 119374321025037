import { Injectable } from '@angular/core';
import { createFeatureStateSelector, Store } from "mini-rx-store";
import { shareNowReduxStoreName, ShareNowReduxStoreState } from "../state/share-now.reducer";
import { LocalStorageService } from "../../services/local-storage.service";
import {
    shareNowCompletedAction,
    shareNowConfirmAction, shareNowLoadStateAction, shareNowRepeatConfirmationAction,
    shareNowRequestAction,
    shareNowResetAction
} from "../state/share-now.actions";
import { ShareNowRequestModel } from "../models/share-now-request.model";
import { FileRequest } from "../../../../generated/api";
import { ShareNowConfirmRequestModel } from "../models/share-now-confirm-request.model";

const getShareNowState = createFeatureStateSelector<ShareNowReduxStoreState>(shareNowReduxStoreName)

@Injectable({
    providedIn: 'root'
})
export class ShareNowService {
    private readonly shareNowState$ = this.store.select(getShareNowState);

    constructor(private readonly store: Store, private readonly localStorageService: LocalStorageService) {
        this.loadShareNowState();
    }

    public loadShareNowState() {
        const shareNowState = this.localStorageService.getShareNowState();
        if (undefined !== shareNowState) {
            this.store.dispatch(shareNowLoadStateAction(shareNowState));
        }
    }

    public requestShareNowPublicSharing(fileRequest: Array<FileRequest>,
                    files: File[],
                    message: string,
                    numDaysToShare: number,
                    numFilesToShare: number,
                    recipientEmail: string,
                    senderEmail: string,
                    subject: string) {
        const shareNowRequestModel: ShareNowRequestModel = {
            fileRequest: fileRequest,
            files: files,
            message: message,
            numDaysToShare: numDaysToShare,
            numFilesToShare: numFilesToShare,
            recipientEmail: recipientEmail,
            senderEmail: senderEmail,
            subject: subject
        };
        this.store.dispatch(shareNowRequestAction(shareNowRequestModel));
    }

    public getShareNowState() {
        return this.shareNowState$;
    }

    public resetShareNowState() {
        this.store.dispatch(shareNowResetAction());
    }

    public confirmShareNowPublicSharing(email: string, code: string, requestUUID: string) {
        const shareNowConfirmRequestModel: ShareNowConfirmRequestModel = {
            code: code,
            email: email,
            requestUUID: requestUUID
        }
        this.store.dispatch(shareNowConfirmAction(shareNowConfirmRequestModel));
    }

    public setCompleted(): void {
        this.store.dispatch(shareNowCompletedAction())
    }

    public setStateToConfirm(): void {
        this.store.dispatch(shareNowRepeatConfirmationAction());
    }
}
