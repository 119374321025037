import {Component} from '@angular/core';
import { NotificationService } from "./services/notification.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    template = 'home';
    title = 'hivetransfer-fe';

    constructor(private notificationService: NotificationService) {
        // empty.
    }

    showSuccess() {
        this.notificationService.showSuccess('This is a success message');
    }
}
