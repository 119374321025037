import { Injectable } from "@angular/core";
import { Actions, createEffect } from "mini-rx-store";
import { ConfirmEmailRequest, PublicShareResourceService, SharingRequest } from "../../../../generated/api";
import { ShareNowRequestModel } from "../models/share-now-request.model";
import { catchError, map, of, switchMap } from "rxjs";
import {
    shareNowConfirmAction, shareNowConfirmFailureAction, shareNowConfirmSuccessAction,
    shareNowRequestAction,
    shareNowRequestFailureAction,
    shareNowRequestSuccessAction
} from "./share-now.actions";
import { ofType, toPayload } from "ts-action-operators";
import { ShareNowConfirmRequestModel } from "../models/share-now-confirm-request.model";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class ShareNowEffects {
    $shareNow = createEffect(
        this.action$.pipe(
            ofType(shareNowRequestAction),
            toPayload(),
            switchMap((payload: ShareNowRequestModel) => {
                const sharingRequest: SharingRequest = {
                    senderEmail: payload.senderEmail,
                    recipientEmail: payload.recipientEmail,
                    fileRequest: payload.fileRequest,
                    numFilesToShare: payload.numFilesToShare,
                    numDaysToShare: payload.numDaysToShare,
                    subject: payload.subject,
                    message: payload.message
                };
                return this.publicShareResourceService.requestShare(sharingRequest).pipe(
                    map((response) => shareNowRequestSuccessAction(response)),
                    catchError((error) => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 400) {
                                return of(shareNowRequestFailureAction(error.error));
                            }
                            if (error.status && error.status === 409) {
                                const message = error.error.message as string;
                                return of(shareNowRequestFailureAction({ error: message }));
                            }
                        }
                        return of(shareNowRequestFailureAction(error));
                    })
                );
            })
        )
    );

    $confirmEmail = createEffect(
        this.action$.pipe(
            ofType(shareNowConfirmAction),
            toPayload(),
            switchMap((payload: ShareNowConfirmRequestModel) => {
                const shareConfirmRequest: ConfirmEmailRequest = {
                    code: payload.code,
                    email: payload.email,
                    requestUUID: payload.requestUUID
                };
                return this.publicShareResourceService.confirmEmail(shareConfirmRequest).pipe(
                    map((response) => shareNowConfirmSuccessAction({ confirmationCode: payload.code })),
                    catchError((error) => of(shareNowConfirmFailureAction({ error: error.error.message as string })))
                );
            })
        )
    );

    constructor(private action$: Actions, private publicShareResourceService: PublicShareResourceService) {
        // empty.
    }
}
