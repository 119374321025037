import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MaterialModule } from "./material/material.module";
import { HomeComponent } from './components/home/home.component';
import { TranslocoRootModule } from "./transloco-root/transloco-root.module";
import { LocalStorageService } from "./services/local-storage.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgPipesModule } from "ngx-pipes";
import { ApiModule, Configuration } from "../../generated/api";
import { environment } from "../environments/environment";
import {
    EmailVerificationConfirmationComponent
} from './components/dialogs/email-verification-confirmation/email-verification-confirmation.component';
import { StoreModule } from "mini-rx-store-ng";
import { ReduxDevtoolsExtension } from "mini-rx-store";
import { ShareNowModule } from "./share-now/share-now.module";
import { RetrieveFilesModule } from "./retrieve-files/retrieve-files.module";
import { ToastrModule } from "ngx-toastr";
import { NotificationService } from "./services/notification.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { KeycloakService } from "keycloak-angular";
import { AuthenticationWrapperService } from "./services/authentication-wrapper.service";
import { InitialsPipe } from './shared/pipes/initials.pipe';

const apiConfiguration: Configuration = new Configuration({
    basePath: environment.apiBasePath
});

function initializeKeycloak(keycloak: KeycloakService) {
    return () => keycloak.init({
        config: {
            url: environment.keycloakUrl,
            realm: environment.keycloakRealm,
            clientId: environment.keycloakClientId
        },
        initOptions: {
            // onLoad: 'login-required',
            onLoad: 'check-sso',
            checkLoginIframe: false
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: [ '/assets', '/api/public' ]
    })
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        EmailVerificationConfirmationComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        TranslocoRootModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgPipesModule,
        ApiModule.forRoot(() => apiConfiguration),
        StoreModule.forRoot({
            extensions: [
                new ReduxDevtoolsExtension({
                    name: 'MiniRx Redux Dev Tools',
                    maxAge: 25,
                    latency: 1000,
                })
            ],
            reducers: [],
            metaReducers: []
        }),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            maxOpened: 10,
            autoDismiss: true
        }),
        SharedModule,
        ShareNowModule,
        RetrieveFilesModule
    ],
    providers: [
        provideAnimationsAsync(),
        LocalStorageService,
        NotificationService,
        KeycloakService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            deps: [ KeycloakService ],
            multi: true
        },
        AuthenticationWrapperService
    ],
    exports: [],
    bootstrap: [ AppComponent ]
})
export class AppModule {
}
