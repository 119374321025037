import { action, payload } from "ts-action";
import { ShareNowRequestModel } from "../models/share-now-request.model";
import { ShareNowResponseModel } from "../models/share-now-response.model";
import { ShareNowConfirmRequestModel } from "../models/share-now-confirm-request.model";
import { ShareNowUploadRequestModel } from "../models/share-now-upload-request.model";
import { ShareNowUploadResponseModel } from "../models/share-now-upload-response.model";
import { ShareNowReduxStoreState } from "./share-now.reducer";

export const shareNowLoadStateAction = action('SHARE_NOW.LOAD_STATE', payload<ShareNowReduxStoreState>());

export const shareNowRequestAction = action('SHARE_NOW.REQUEST', payload<ShareNowRequestModel>());
export const shareNowRequestSuccessAction = action('SHARE_NOW.REQUEST.SUCCESS', payload<ShareNowResponseModel>());
export const shareNowRequestFailureAction = action('SHARE_NOW.REQUEST.FAILURE', payload<{ error: string }>());

export const shareNowConfirmAction = action('SHARE_NOW.CONFIRM', payload<ShareNowConfirmRequestModel>());
export const shareNowConfirmSuccessAction = action('SHARE_NOW.CONFIRM.SUCCESS', payload<{ confirmationCode: string }>());
export const shareNowConfirmFailureAction = action('SHARE_NOW.CONFIRM.FAILURE', payload<{ error: string }>());

export const shareNowUploadRequestAction = action('SHARE_NOW.UPLOAD.REQUEST', payload<ShareNowUploadRequestModel>());
export const shareNowUploadRequestSuccessAction = action('SHARE_NOW.UPLOAD.REQUEST.SUCCESS', payload<ShareNowUploadResponseModel>());
export const shareNowUploadRequestFailureAction = action('SHARE_NOW.UPLOAD.REQUEST.FAILURE', payload<{ error: string }>());

export const shareNowResetAction = action('SHARE_NOW.RESET');
export const shareNowLoadDataFromStorage = action('SHARE_NOW.LOAD_DATA_FROM_STORAGE');
export const shareNowLoadDataFromStorageSuccess = action('SHOW_NOW.LOAD_DATA_FROM_STORAGE.SUCCESS', payload<ShareNowReduxStoreState>());

export const shareNowCompletedAction = action('SHARE_NOW.COMPLETED');
export const shareNowRepeatConfirmationAction = action('SHARE_NOW.REPEAT_CONFIRMATION');
